import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import SEO from "../../../components/seo";

// SVG
import Logo from "../../../images/sparkrise-logo-vbalance.svg";

// Cookie Consent - Tutorial https://www.improvebadcode.com/gatsby-gdpr-cookie-consent/
// https://www.gatsbyjs.com/plugins/gatsby-plugin-gdpr-cookies/#initialize-and-track
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

// CSS
import '../../../styles/campaigns/topgun.css';

// markup
const IndexPage = () => {
  return (
    <div className="flex flex-col min-h-screen font-sans bg-light-gray">
      <main className="bg-white">
        <CookieConsent
          location="top"
          buttonText="Click to Accept"
          cookieName="sparkrise-google-analytics-consent"
          onAccept={() => {
            Cookies.set("sparkrise-facebook-pixel-consent", "true", { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), sameSite: 'lax' });
            initializeAndTrack(useLocation);
          }}
          style={{ justifyContent: "center" }}
          contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
          buttonStyle={{
            marginTop: "0px"
          }}
          // overlay
          // overlayStyle={{
          //   position: "fixed",
          //   left: 0,
          //   top: 0,
          //   width: "100%",
          //   height: "100%",
          //   zIndex: "999",
          //   backgroundColor: "rgba(0,0,0,1)",
          // }}
        >
          This site uses cookies ...{" "}
          <a href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="underline lg:mr-2">
            Privacy Policy
          </a>
        </CookieConsent>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              if (navigator && navigator.serviceWorker && navigator.serviceWorker.getRegistrations) {
                  navigator.serviceWorker.getRegistrations().then((registrations) => {
                    for (let registration of registrations) {
                      registration.unregister()
                    }
                })
              }
            `
          }} />
        <SEO
          keywords={[`sparkrise`, `charity`, `uso`, `twainmania`, `twain`, `mania`, `change`, `top gun`, `spark`, `rise`, `sparks`, `change`, `top`, `gun`, `val`, `kilmer`, `iceman`, `tom`, `cruise`, `tom cruise`, `maverick`, `viper`, `goose`, `sundown`, `stinger`, `jester`, `slider`, `hollywood`, `cougar`, `wolfman`, `merlin`, `charlie`, `chipper`, `display`, `beem`, `nft`, `crypto`]}
          title="Top Gun Celebration - Live Viewing Party!"
          description="Join our once-in-a-lifetime live-streaming event! The evening begins with Val’s Potluck Movie Night. It’s free to attend, but please give what you can by clicking on the donate button during the event. Proceeds will benefit the USO and Val Kilmer’s TwainMania Foundation. Then click on over to the Top Gun Celebration, Both parties will be joined by cast members, celebrities, musicians, and military elites from bases across the US. Let's party together, to honor our military families and celebrate one of the greatest action movies of all time!"
          meta={[
            {
              name: "image",
              content: "https://www.sparkrise.com/c/top-gun-celebration-2021/stream-layout.png"
            },
            {
              property: "og:image",
              content: "https://www.sparkrise.com/c/top-gun-celebration-2021/stream-layout.png"
            },
          ]}
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="194x194" href="/favicon-194x194.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#e2662a" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        <header className="w-full py-3">
          {/* <div className="flex items-center justify-center px-6 space-x-4">
            <p className="pb-1 mt-4 font-semibold text-xxs lg:text-base">
              Presented by
            </p>
            <StaticImage
              src="../../../images/sparkrise-logo.svg"
              alt="sparkrise logo"
              height="40"
              className="mb-4"
            />
            <p className="mt-4 font-semibold text-xxs lg:text-base">
              and
            </p>
            <StaticImage
              src="../../../images/beem-simple-logo.svg"
              alt="beem logo"
              height="48"
            />
          </div> */}
        </header>

        <section className="flex justify-center px-6 lg:px-12 bg-light-purple py-14">
          <div className="flex flex-col items-center justify-center w-full xl:container lg:flex-row">
            <div className="w-full mx-auto mb-12 lg:w-7/12 lg:order-2 lg:mb-0">
              <div className="py-4 pl-1 pr-8 mb-12 ribbon-right bg-purple lg:hidden">
                <p className="z-10 mb-0 text-base text-white">
                  Live Online Streaming Event<br />
                  Saturday September 25th, 5pm PT / 8pm ET
                </p>
              </div>
              <div className="text-center">
                <StaticImage
                  src="../../../images/stream-layout.png"
                  alt="mobile and desktop streaming example"
                />
              </div>
              <div className="flex justify-center mb-4 flex-nowrap">
                <StaticImage src="../../../images/updated-badge-group.svg" alt="badge icons" />
              </div>
              <div className="flex justify-center space-x-6 font-medium text-xxs flex-nowrap">
                <p>Benefiting the USO</p>
                <p>Exclusive NFTs</p>
                <p>Benefiting TwainMania</p>
              </div>
            </div>
            <div className="w-full lg:w-5/12 lg:order-1 lg:mr-8">
              <div className="hidden py-4 pl-1 pr-8 mb-12 ribbon-right bg-purple lg:block">
                <p className="z-10 mb-0 text-2xl text-white">
                  Live Online Streaming Event<br />
                  Saturday September 25th, 5pm PT / 8pm ET
                </p>
              </div>
              <center>
                {/* <div className="mb-8 c-body text-black font-bold">
                  Join us online for a fun night filled with celebrities, <br />
                  musical performances and military top guns!
                </div> */}
                <h1 className="mb-8 c-h3 text-black font-bold uppercase">
                  Val’s Potluck Movie Night
                </h1>
                <div>
                  Virtual Star-Studded Fundraising Party for The USO & TwainMania<br />
                  (Free to Attend Online – Give What You Can)<br /><br />
                </div>
                <div>
                  Help support the USO and TwainMania Foundation
                  <br /><br />
                </div>
                <div>
                  <a href="https://www.sparkrise.com/go/topgun-pledge" target="_blank" rel="noreferrer" className="mb-4 c-button1">
                    Donate Now
                  </a>
                </div>
                <br /><br />
                <div className="mb-8 c-body text-black">
                  Thank you to everyone who participated in this event.  If you would like to be informed about
                  future events, please enter your email address below.  A recording of portions of this event
                  may be available in the near future as well.  If you would like to be notified when they are,
                  please enter your email below.  Let's continue to #SparkChange!
                </div>
                <html
                  dangerouslySetInnerHTML={{
                    __html: `
                    <!-- Begin Mailchimp Signup Form -->
                    <style type="text/css">
                        #mc_embed_signup{background:#faf8ff; clear:left; font:14px Helvetica,Arial,sans-serif; width:100%;}
                        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                        We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
                        /* MailChimp Form Embed Code - Horizontal Super Slim - 12/16/2015 v10.7
                        Adapted from: http://blog.heyimcat.com/universal-signup-form/ */

                        #mc_embed_signup form {text-align:center; padding:10px 0 10px 0;}
                        .mc-field-group { display: inline-block; } /* positions input field horizontally */
                        #mc_embed_signup input.email {font-family:"Open Sans","Helvetica Neue",Arial,Helvetica,Verdana,sans-serif; font-size: 15px; border: 1px solid #ABB0B2;  -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; color: #343434; background-color: #fff; box-sizing:border-box; height:32px; padding: 0px 0.4em; display: inline-block; margin: 0; width:350px; vertical-align:top;}
                        #mc_embed_signup label {display:block; font-size:16px; padding-bottom:10px; font-weight:bold;}
                        #mc_embed_signup .clear {display: inline-block;} /* positions button horizontally in line with input */
                        #mc_embed_signup .button {font-size: 13px; border: none; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; letter-spacing: .03em; color: #fff; background-color: #aaa; box-sizing:border-box; height:32px; line-height:32px; padding:0 18px; display: inline-block; margin: 0; transition: all 0.23s ease-in-out 0s;}
                        #mc_embed_signup .button:hover {background-color:#777; cursor:pointer;}
                        #mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%;margin: 0 5%; clear: both;}
                        #mc_embed_signup div.response {margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; float:left; top:-1.5em; z-index:1; width:80%;}
                        #mc_embed_signup #mce-error-response {display:none;}
                        #mc_embed_signup #mce-success-response {color:#529214; display:none;}
                        #mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}
                        @media (max-width: 768px) {
                            #mc_embed_signup input.email {width:100%; margin-bottom:5px;}
                            #mc_embed_signup .clear {display: block; width: 100% }
                            #mc_embed_signup .button {width: 100%; margin:0; }
                        }
                    </style>
                    <div id="mc_embed_signup">
                    <form action="https://sparkrise.us5.list-manage.com/subscribe/post?u=c69cb9a17ba4e42a9cc102f2d&amp;id=a98861fd86" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                        <div id="mc_embed_signup_scroll">
                        <label for="mce-EMAIL">Get notified of future events and when event recordings are available</label>
                        <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
                        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_c69cb9a17ba4e42a9cc102f2d_a98861fd86" tabindex="-1" value=""></div>
                        <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                        </div>
                    </form>
                    </div>
                    <!--End mc_embed_signup-->
                    `
                  }} />
              </center>
            </div>
          </div>
        </section>

        <body className="w-full px-6 py-12 mx-auto bg-white xl:w-full xl:container">
          <div className="flex flex-col items-start justify-center lg:flex-row">
            <div className="w-full pr-8 lg:w-10/12">
              <center>
                <h5 className="mb-4 c-h5 text-purple">Information</h5>
                <p className="text-xs">
                  Join our once-in-a-lifetime live-streaming event! The evening begins with Val’s
                  Potluck Movie Night. It’s free to attend, but please give what you can by clicking
                  on the donate button during the event. Proceeds will benefit the USO and Val Kilmer’s
                  TwainMania Foundation. Then click on over to the Top Gun Celebration, Both parties
                  will be joined by cast members, celebrities, musicians, and military elites from bases
                  across the US. Let's party together, to honor our military families and celebrate one
                  of the greatest action movies of all time!
                </p>
                <p className="text-xs italic">
                  * Exclusive NFTs and the opportunity to interact with the panelists.
                </p>
              </center>
              <div className="flex flex-col items-start justify-center lg:flex-row">
                <div className="w-full pr-8 lg:w-6/12">
                  <p className="mb-4 font-bold text-purple">Agenda #1 Val's Potluck Movie Night (Pre-screening Party)</p>
                  <div>
                    <center>
                      <div>
                        Help support the USO and TwainMania Foundation
                        <br /><br />
                      </div>
                      <div>
                        <a href="https://www.sparkrise.com/go/topgun-pledge" target="_blank" rel="noreferrer" className="mb-4 c-button1">
                          Donate Now
                        </a>
                      </div>
                      <br />
                    </center>
                  </div>
                  <p className="text-xs">
                    5:00pm PT / 8:00pm ET
                    <br />
                    The party will be live-streamed from Val Kilmer’s HelMel Studio in Los
                    Angeles. Our show includes some of today's top celebrities, eclectic
                    artists and musicians, here to entertain you while raising funds and having fun.
                  </p>
                  <p className="text-xs">
                    5:35pm PT / 8:35pm ET
                    <br />
                    Now it's time to hop on over to the movie portion of the night. Tonight's
                    movie is Top Gun – where there will be military guests, celebrity surprises,
                    musical artists, and more fun to be had... Purchase your tickets in advance,
                    or in the moment.
                  </p>
                </div>
                <div className="w-full pr-8 lg:w-6/12">
                  <p className="mb-4 font-bold text-purple">Agenda #2 Top Gun Celebration (Interactive Movie Screening)</p>
                  <p className="text-xs">
                    5:30pm PT / 8:30pm ET
                    <br />
                    Dinner and a Movie: The movie portion of the night opens with our host
                    checking in with viewing parties from across the nation and interacting
                    with some of our onstage guests, including Val Kilmer (Iceman – in chat),
                    Rick Rossovich (Slider) and Barry Tubb (Wolfman), as well as some of our
                    high ranking military guests.
                  </p>
                  <p className="text-xs">
                    5:45pm PT / 8:45pm ET
                    <br />
                    The movie begins – Stay tuned… because you never know who might show
                    up! Don’t be surprised when you see some special celebrity pop-ins and
                    some once-in-a-lifetime performances!
                  </p>
                  <p className="text-xs">
                    7:15pm PT / 10:15pm ET
                    <br />
                    The movie ends, and our host wraps-up with more special guests and
                    announcements about upcoming events.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/12">
              <h5 className="mb-4 c-h5 text-purple">Sponsors</h5>
              <p className="text-xs font-bold">
                Please visit our sponsors.<br /><br />
                Each click or registration makes a donation!
              </p>
              <div className="flex flex-row flex-nowrap lg:flex-col">
                <div className="w-1/2 mb-4 lg:w-full">
                  <Link to="https://www.sparkrise.com/go/dakkota-topgun" target="_blank">
                    <StaticImage
                      src="../../../images/Dakkota_Logo.svg"
                      alt="dakkota logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex flex-row flex-nowrap lg:flex-col">
                <div className="w-1/2 mb-4 lg:w-full">
                  <Link to="https://www.sparkrise.com/go/wahoos-topgun" target="_blank">
                    <StaticImage
                      src="../../../images/WahoosLogo_500.png"
                      alt="wahoos logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex flex-row flex-nowrap lg:flex-col">
                <div className="w-1/2 mb-4 lg:w-full">
                  <Link to="https://www.sparkrise.com/go/display-topgun" target="_blank">
                    <StaticImage
                      src="../../../images/display-logo.png"
                      alt="display logo"
                    />
                    <StaticImage
                      src="../../../images/SparkRise Display QR Code.jpg"
                      alt="display qr code"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <footer className="container flex flex-col justify-center items-center lg:flex-row w-full"
            aria-label="main footer"
            role="contentinfo">
            <Link to="https://www.sparkrise.com/" className="mr-0 lg:mr-4">
              <Logo style={{ height: "68px", width: "218px" }} />
            </Link>

            <p className="text-center flex flex-col md:flex-row">
              <table className="center">
                <tr>
                  Copyright © {new Date().getFullYear()} SparkRaiser, Inc. All Rights Reserved.
                </tr>
                <tr>
                  <Link to="https://www.sparkrise.com/disclosurestatements" className="underline lg:mr-2">
                    Disclosures
                  </Link>{" "}
                  <span className="md:inline px-2"> | </span>
                  <Link to="https://www.sparkrise.com/termsofservice" className="underline lg:mr-2">
                    Terms
                  </Link>{" "}
                  <span className="md:inline px-2"> | </span>
                  <Link to="https://www.sparkrise.com/privacypolicy" className="underline lg:mr-2">
                    Privacy
                  </Link>
                </tr>
              </table>
            </p>
          </footer>
        </body>
      </main>
    </div>
  );
};

export default IndexPage;
